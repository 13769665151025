import { GatsbyLinkProps } from "gatsby";
import React from "react";
import styled from "styled-components";
import { ButtonLinkSolid } from "../../components/button";
import Video from "../../components/media/video";
import { Paragraph } from "../../components/typography";
import { Routes } from "../../data/routing";
import { PageElement } from "../../graphql/page-common";
import { useMatchMedia } from "../../hooks/use-match-media";
import { onScrollAnimationDesktop } from "../../utilities/animation-helper";
import { getElementImage, getElementText, getElementVideo } from "../../utilities/pimcore-data-transformer";

interface AboutAmsterdamProps {
  content: Array<PageElement>;
}

const AboutAmsterdam: React.FC<AboutAmsterdamProps> = ({ content }) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const tl = React.useRef<GSAPTimeline>();
  const matchMobile = useMatchMedia("(max-width: 768px)");

  React.useEffect(() => {
    tl.current = onScrollAnimationDesktop(wrapperRef.current, [wrapperRef?.current?.children || []], matchMobile, [
      "6rem",
      "10rem",
    ]);
    return () => {
      tl?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <Wrapper ref={wrapperRef}>
      <HeadlineWrapper>
        <Headline dangerouslySetInnerHTML={{ __html: getElementText(content[0]) }} />
      </HeadlineWrapper>
      <VideoWrapper>
        <Video placeholder={getElementImage(content[1])} video={getElementVideo(content[1])} />
      </VideoWrapper>
      <Content>
        <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[2]) }} />
        <ButtonLink to={Routes.amsterdam}>Discover Amsterdam</ButtonLink>
      </Content>
    </Wrapper>
  );
};

export default AboutAmsterdam;

const Headline = styled.h3.attrs({
  className: "font-light text-white leading-17 text-6xl mb-8 tablet:text-subHeadline",
})``;

const Wrapper = styled.div.attrs({
  className: "tablet:grid tablet:grid-cols-12 gap-4 mb-16 px-5 tablet:mb-36 scrollspy",
})``;
const ButtonLink = styled(ButtonLinkSolid).attrs<GatsbyLinkProps<any>>({
  className: "mt-8 tablet:mt-12",
})<GatsbyLinkProps<any>>``;
const VideoWrapper = styled.div.attrs({
  className: "tablet:col-span-7 tablet:col-start-6 tablet:row-start-1 tablet:row-span-3 mb-8 tablet:mb-0 ",
})``;
const HeadlineWrapper = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-1 tablet:row-span-1",
})``;
const Content = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-2 tablet:row-span-1 tablet:pr-14",
})``;
