import React from "react";
import styled from "styled-components";
import MainSection from "../../components/main-section/main-section";
import { Hero, HeroHeadline } from "../../components/hero";
import SectionGallery from "../../components/section-gallery";
import OfficeFeatures from "../../sections/office/office-features";
import OfficeLocation from "../../sections/office/office-location";
import { JobsSearchTile, TileNavigation } from "../../components/tile-navigation";
import AboutAmsterdam from "../../sections/office/about-amsterdam";
import Layout from "../../components/layout";
import { JobOffersCountInterface } from "../../graphql/jobs";
import { graphql, PageProps } from "gatsby";
import { OfficePageContentInterface } from "../../graphql/office-page";
import { transformHeroData } from "../../utilities/pimcore-data-transformer";
import Scrollspy, { ScrollspyProps } from "../../components/scrollspy/scrollspy";

interface OfficePageDataInterface {
  pimcore: {
    jobOffersCount: JobOffersCountInterface;
    content: OfficePageContentInterface;
  };
}
const labels = ["Welcoming, collaborative and smart", "Your place to thrive", "Where we’re based", "We just love this city"];

const OfficePage: React.FC<PageProps<OfficePageDataInterface>> = ({ data }) => {
  const content = data.pimcore.content;

  return (
    <Layout
      navCurrentItem="office"
      title={content.metaTitle}
      description={content.metaDescription}
      canonical={content.metaCanonical}
      openGraph={{ title: content.metaOgTitle, description: content.metaOgTitle, image: content.metaOgImage }}
      schemaOrg={content.metaSchema}
    >
      <Header>
        <Hero {...transformHeroData(content)}>
          <HeroHeadline dangerouslySetInnerHTML={{ __html: content.heroTitle || "" }} />
        </Hero>
      </Header>
      <Main>
        <MainSection
          headline={content.mainSectionHeadline}
          subHeadline={content.mainSectionSubheadline}
          content={content.mainSectionContent}
          background={content.mainSectionBackground}
          backgroundMobile={content?.mainSectionBackgroundMobile}
        >
          <SectionGallery images={content.gallery} />
        </MainSection>
        <OfficeFeatures content={content.features} />
        <OfficeLocation content={content.location} background={content.locationBackground} backgroundMobile={content.locationBackgroundMobile} />
        <AboutAmsterdam content={content.discover} />
        <TileNavigation top={"work"} bottom={"team"}>
          <JobsSearchTile jobsCount={data.pimcore.jobOffersCount.totalCount} />
        </TileNavigation>
      </Main>
      <Scrollspy labels={labels} gap={4} />
    </Layout>
  );
};

const Header = styled.header.attrs({ className: "h-screen" })``;

const Main = styled.main.attrs({ className: "x-background overflow-x-hidden -mt-24" })``;

export const query = graphql`
  query {
    pimcore {
      ...jobOffersCount
      ...officePageData
    }
  }
`;

export default OfficePage;
