import React from "react";
import styled from "styled-components";
import QuoteElement from "../../components/quote-element/quote-element";
import FeaturesList from "../../components/features-list";
import { Headline2, Paragraph } from "../../components/typography";
import { theme } from "../../styles/styles";
import LocationHeader from "./location-header";
import { PageElement } from "../../graphql/page-common";
import { getElementImage, getElementText } from "../../utilities/pimcore-data-transformer";
import { PimcoreImage } from "../../components/pimcore-image";
import { ImageDataInterface } from "../../graphql/common";
import { useMatchMedia } from "../../hooks/use-match-media";
import { BikeIcon, CarIcon, MetroTrainIcon, TramIcon } from "../../images/icons/Icons";
import { onScrollAnimationDesktop, returnRefArray } from "../../utilities/animation-helper";

interface OfficeLocationProps {
  content: Array<PageElement>;
  background: ImageDataInterface;
  backgroundMobile?: ImageDataInterface;
}

interface ImageProps {
  $size?: "small";
  $mobileHidden?: boolean;
  $withGap?: boolean;
}

interface ParagraphProps {
  $padding?: string;
}

const OfficeLocation: React.FC<OfficeLocationProps> = ({ content, background, backgroundMobile }) => {
  const matchMobile = useMatchMedia("(max-width: 768px)");
  const tl1 = React.useRef<GSAPTimeline>();
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const childrenIndexSection = [0, 1, 2, 3, 4, 5, 6, 7];
    const childrenChildIndexSection = [5, 7];

    tl1.current = onScrollAnimationDesktop(
      wrapperRef.current,
      returnRefArray(wrapperRef?.current?.children[0], childrenIndexSection, childrenChildIndexSection),
      matchMobile
    );

    return () => {
      tl1?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <ScrollSectionWrapper>
      <LocationHeader content={content} background={background} backgroundMobile={backgroundMobile} />
      <ContentWrapper ref={wrapperRef}>
        <Grid>
          <MediaCell1>
            <QuoteElement
              message="It’s a great location, there’s a strong multi-cultural feeling."
              name="Maya"
              position="SEO/SEA Manager"
              modifier="left-4 bottom-4 tablet:left-auto tablet:bottom-[33rem] tablet:right-[25rem]"
            />
            <ImageWrapper>
              <PimcoreImage
                image={getElementImage(content[2])}
                mobileImage={getElementImage(content[2], true)}
                withAspectRatio
              />
            </ImageWrapper>
          </MediaCell1>
          <MediaCell2_1>
            <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[3]) }} />
          </MediaCell2_1>
          <MediaCell3_1>
            <Headline2 dangerouslySetInnerHTML={{ __html: getElementText(content[4]) }} />
          </MediaCell3_1>
          <MediaCell4_1>
            <ImageWrapper $mobileHidden>
              <PimcoreImage
                image={getElementImage(content[5])}
                mobileImage={getElementImage(content[5], true)}
                withAspectRatio
              />
            </ImageWrapper>
          </MediaCell4_1>
          <MediaCell5_1>
            <ImageWrapper>
              <PimcoreImage
                image={getElementImage(content[6])}
                mobileImage={getElementImage(content[6], true)}
                withAspectRatio
              />
            </ImageWrapper>
          </MediaCell5_1>
          <MediaCell6_1>
            <ParagraphWrapper $padding="tablet:pr-6">
              <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[7]) }} />
            </ParagraphWrapper>
            <FeaturesList
              margin="mt-10 tablet:mt-14 mb-16 tablet:mb-0"
              features={[
                {
                  icon: CarIcon,
                  label: (
                    <>
                      underground
                      <br />
                      car park
                    </>
                  ),
                },
                {
                  icon: MetroTrainIcon,
                  label: (
                    <>
                      close to train
                      <br />
                      and metro station
                    </>
                  ),
                },
              ]}
            />
          </MediaCell6_1>
          <MediaCell7_1>
            <ImageWrapper>
              <PimcoreImage
                image={getElementImage(content[8])}
                mobileImage={getElementImage(content[8], true)}
                withAspectRatio
              />
            </ImageWrapper>
          </MediaCell7_1>
          <MediaCell8_1>
            <ParagraphWrapper $padding="tablet:pr-16">
              <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[9]) }} />
            </ParagraphWrapper>
            <FeaturesList
              margin="mt-10 tablet:mt-14 mb-16 tablet:mb-0"
              features={[
                { icon: BikeIcon, label: "secure bike parking" },
                {
                  icon: TramIcon,
                  label: (
                    <>
                      local tram
                      <br />
                      services
                    </>
                  ),
                },
              ]}
            />
          </MediaCell8_1>
        </Grid>
      </ContentWrapper>
    </ScrollSectionWrapper>
  );
};

const ScrollSectionWrapper = styled.div.attrs({ className: "scrollspy" })``;

const ContentWrapper = styled.div.attrs({
  className: "relative -mt-8 tablet:-mt-36",
})``;
const ParagraphWrapper = styled.div.attrs<ParagraphProps>(({ $padding }) => ({
  className: `${$padding}`,
}))<ParagraphProps>``;
const Grid = styled.div.attrs({
  className: "tablet:grid tablet:grid-cols-12 mb-14 tablet:mb-36 px-5 tablet:gap-4",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(2, auto);
  }
`;

const MediaCell1 = styled.div.attrs({
  className:
    "tablet:col-start-2 tablet:col-span-6 tablet:mr-16 tablet:row-span-3 tablet:row-start-1 relative mb-8 tablet:mb-0",
})``;

const MediaCell2_1 = styled.div.attrs({
  className:
    "tablet:col-start-8 tablet:col-span-4 tablet:row-start-1 tablet:row-span-1 mb-12 tablet:mb-0 tablet:pt-56 tablet:ml-8 tablet:pr-10",
})``;
const MediaCell3_1 = styled.div.attrs({
  className: "tablet:row-start-4 tablet:row-span-1 tablet:col-start-2 tablet:col-span-3 tablet:mt-32 mb-8 tablet:mb-0",
})``;
const MediaCell4_1 = styled.div.attrs({
  className: "tablet:col-start-7 tablet:col-span-6 tablet:row-start-3 tablet:row-span-4 tablet:ml-14",
})``;
const MediaCell5_1 = styled.div.attrs({
  className:
    "tablet:col-start-7 tablet:col-span-6 tablet:row-start-7 tablet:row-span-3 tablet:ml-14 tablet:mr-14 mb-8 tablet:mb-0",
})``;
const MediaCell6_1 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-5 tablet:row-span-4",
})``;
const MediaCell7_1 = styled.div.attrs({
  className:
    "tablet:col-start-3 tablet:col-span-5 tablet:row-start-9 tablet:row-span-4 tablet:mr-16 tablet:pt-20 mb-8 tablet:mb-0",
})``;
const MediaCell8_1 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-11 tablet:row-span-2 tablet:pb-7",
})``;

const ImageWrapper = styled.div.attrs<ImageProps>(({ $size, $mobileHidden, $withGap }) => ({
  className: `block overflow-hidden rounded-lg mr-auto ${$size === "small" ? " tablet:w-4/5 w-full" : "w-full"}${
    $mobileHidden ? " hidden tablet:block" : ""
  }${$withGap ? " mb-4" : ""}`,
}))<ImageProps>``;

export default OfficeLocation;
