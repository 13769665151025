import React from "react";
import styled from "styled-components";
import ArrowComponent from "../horizontal-slider/arrow-component";
import { PimcoreImage } from "../pimcore-image";
import { ImageDataInterface } from "../../graphql/common";

interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {}

interface HiddeButton extends ButtonProps {
  hiddeButton: true | false;
}

interface ArrowProps {
  left?: boolean;
}

interface GalleryInterface {
  images: Array<ImageDataInterface | undefined>;
}

const GalleryImagesSlider: React.FC<GalleryInterface> = ({ images }) => {
  const elementsWrapperRef = React.useRef<HTMLDivElement>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const sliderElementsRefs = React.useRef<Array<HTMLElement | null>>([]);
  const dashElementsRefs = React.useRef<Array<HTMLElement | null>>([]);
  const [hiddenPrevButton, setHiddenPrevButton] = React.useState<boolean>(true);
  const [hiddenNextButton, setHiddenNextButton] = React.useState<boolean>(false);

  const currentElementHandler = () => {
    const currentElement =
      parseInt(((elementsWrapperRef.current?.scrollLeft || 0) / (sliderElementsRefs.current[1]?.getBoundingClientRect().width || 0)).toFixed()) + 1;

    dashElementsRefs.current.forEach((dash, index) => {
      if (index + 1 === currentElement) {
        dash?.classList.remove("opacity-50");
        dash?.classList.add("opacity-100");
      } else {
        dash?.classList.remove("opacity-100");
        dash?.classList.add("opacity-50");
      }
    });

    setHiddenPrevButton(currentElement === 1);
    setHiddenNextButton(currentElement === images.length);
  };

  const buttonHandler = (direction: number) => {
    if (direction === 1) {
      elementsWrapperRef.current?.scrollBy({
        top: 0,
        left: sliderElementsRefs.current[0]?.getBoundingClientRect().width,
        behavior: "smooth",
      });
    }
    if (direction === -1) {
      elementsWrapperRef.current?.scrollBy({
        top: 0,
        left: (sliderElementsRefs.current[0]?.getBoundingClientRect().width || 0) * -1,
        behavior: "smooth",
      });
    }
  };

  const allSliderImages = images?.map((image, index) => {
    return (
      <SingleSliderElement ref={(el) => (sliderElementsRefs.current[index] = el)} key={`slider-element-${index}`}>
        <ImageWrapper>
          <PimcoreImage image={image} withAspectRatio />
        </ImageWrapper>
      </SingleSliderElement>
    );
  });

  const sliderDash = images.map((el, index) => {
    const clickHandler = () => {
      elementsWrapperRef.current?.scrollTo({
        top: 0,
        left: (sliderElementsRefs.current[index]?.getBoundingClientRect().width || 0) * index,
        behavior: "smooth",
      });
    };
    return (
      <DashElement onClick={clickHandler} ref={(el) => (dashElementsRefs.current[index] = el)} key={`dash-${index}`}>
        <Dash />
      </DashElement>
    );
  });

  React.useEffect(() => {
    currentElementHandler();
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <SliderElementsWrapper ref={elementsWrapperRef} onScroll={currentElementHandler}>
        {allSliderImages}
      </SliderElementsWrapper>
      <DashWrapper>{sliderDash}</DashWrapper>
      <ControlWrapper>
        <ButtonWithArrow hiddeButton={hiddenPrevButton} onClick={() => buttonHandler(-1)} aria-label="previous arrow">
          <ArrowWrapper left>
            <IcoWrapper>
              <ArrowComponent />
            </IcoWrapper>
          </ArrowWrapper>
        </ButtonWithArrow>
        <ButtonWithArrow hiddeButton={hiddenNextButton} onClick={() => buttonHandler(1)} aria-label="next arrow">
          <ArrowWrapper>
            <IcoWrapper>
              <ArrowComponent />
            </IcoWrapper>
          </ArrowWrapper>
        </ButtonWithArrow>
      </ControlWrapper>
    </Wrapper>
  );
};

export default GalleryImagesSlider;

const Wrapper = styled.div.attrs({ className: "w-full h-auto relative" })``;
const SliderElementsWrapper = styled.div.attrs({
  className: "snap-mandatory snap-x w-full flex overflow-x-auto gap-2 tablet:gap-0 px-5 tablet:px-0 revative no-scrollbar tablet:rounded-lg",
})``;
const SingleSliderElement = styled.div.attrs({ className: "snap-center shrink-0 w-full" })``;
const ControlWrapper = styled.div.attrs({ className: "absolute bottom-4 right-10 tablet:right-auto tablet:left-8 tablet:bottom-8" })``;
const Button = styled.button.attrs<ButtonProps>({ className: "" })<ButtonProps>``;
const ButtonWithArrow = styled(Button).attrs<HiddeButton>(({ hiddeButton }) => ({
  className: `${hiddeButton ? "opacity-50 pointer-events-none" : ""} tablet:left-32 tablet:px-0 first:mr-4 first:tablet:mr-4`,
}))<HiddeButton>``;
const ArrowWrapper = styled.div.attrs<ArrowProps>(({ left }) => ({
  className: `${left ? "rotate-180" : ""} tablet:flex  w-10 h-10 rounded-full  border-2 border-white flex items-center justify-center`,
}))<ArrowProps>``;
const IcoWrapper = styled.div.attrs({
  className: "w-2 ml-1",
})``;
const ImageWrapper = styled.div.attrs({ className: "rounded-lg tablet:rounded-none overflow-hidden w-full h-full" })``;
const DashWrapper = styled.div.attrs({ className: "flex absolute left-10 bottom-4 tablet:bottom-8 tablet:left-auto tablet:right-8" })``;
const DashElement = styled.div.attrs({ className: "mr-2 last:mr-0 cursor-pointer" })``;
const Dash = styled.div.attrs({ className: "w-4 h-[2px] bg-white rounded-lg mt-4" })``;