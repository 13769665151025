import React from "react";
import styled from "styled-components";
import { Headline2, Paragraph } from "../../components/typography";
import { ContactAddressData } from "../../data/contact";
import { getElementText } from "../../utilities/pimcore-data-transformer";
import { PageElement } from "../../graphql/page-common";
import { PimcoreImage } from "../../components/pimcore-image";
import { ImageDataInterface } from "../../graphql/common";
import { useMatchMedia } from "../../hooks/use-match-media";
import { onScrollAnimationDesktop, returnRefArray } from "../../utilities/animation-helper";

interface LocationHeaderProps {
  content: Array<PageElement>;
  background: ImageDataInterface;
  backgroundMobile?: ImageDataInterface;
}

const LocationHeader: React.FC<LocationHeaderProps> = ({ content, background, backgroundMobile }) => {
  const matchMobile = useMatchMedia("(max-width: 767.8px)");
  const tl = React.useRef<GSAPTimeline>();
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const mapRef = React.useRef<HTMLDivElement>(null);
  const headlineRef = React.useRef<HTMLHeadingElement>(null);
  const paragraphRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    const indexChildrenSection = [0, 1];
    const childrenChildIndexSection = [1];
    tl.current = onScrollAnimationDesktop(
      wrapperRef.current,
      returnRefArray(wrapperRef.current, indexChildrenSection, childrenChildIndexSection),
      matchMobile
    );

    return () => {
      tl?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <Wrapper ref={wrapperRef}>
      <ImageWrapper ref={mapRef}>
        <PimcoreImage image={background} mobileImage={backgroundMobile} />
        <InfoWrapper>
          <Address>
            {ContactAddressData.street},
            <br /> {ContactAddressData.postcode} {ContactAddressData.city}
          </Address>
        </InfoWrapper>
      </ImageWrapper>
      <Content>
        <Headline2 ref={headlineRef} dangerouslySetInnerHTML={{ __html: getElementText(content[0]) }} />
        <ParagraphWrapper>
          <Paragraph as="div" ref={paragraphRef} dangerouslySetInnerHTML={{ __html: getElementText(content[1]) }} />
        </ParagraphWrapper>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs({
  className: "relative h-[50rem]",
})``;
const ImageWrapper = styled.div.attrs({
  className: "absolute inset-0 -z-x",
})``;
const InfoWrapper = styled.div.attrs({
  className:
    "absolute p-7 bottom-28 tablet:bottom-16 left-4 tablet:left-[56rem] bg-white/[.08] rounded-lg backdrop-blur-sm z-20",
})``;
const Address = styled.p.attrs({
  className: "text-benefitMobile text-white font-bold",
})``;
const Content = styled.div.attrs({
  className: "z-20 relative px-5 pt-16 tablet:px-40 tablet:pt-20 ",
})``;

const ParagraphWrapper = styled.div.attrs({ className: "max-w-sm" })``;

export default LocationHeader;
