import React from "react";
import styled from "styled-components";
import QuoteElement from "../../components/quote-element/quote-element";
import FeaturesList from "../../components/features-list";
import { ImageLeftWrapper, ImageRightWrapper } from "../../components/images/images";
import { Headline2, Headline3, Paragraph } from "../../components/typography";
import { PageElement } from "../../graphql/page-common";
import { getElementImage, getElementImages, getElementText, getElementVideo } from "../../utilities/pimcore-data-transformer";
import { PimcoreImage } from "../../components/pimcore-image";
import { useMatchMedia } from "../../hooks/use-match-media";
import {
  BulbIcon,
  DeskIcon,
  AwardBadgeIcon,
  OvenIcon,
  SleepIcon,
  PlantIcon,
  GlobalIcon,
  BulbPuzzleIcon,
  DownUnderIcon,
  TukTukIcon,
  BoardroomIco,
  CameraIcon,
  TechArenaIcon,
  GreenScreenIcon,
  SoundProofIcon,
  AeroplaneIcon,
  InnovationIcon,
  GlobalEventsIcon,
} from "../../images/icons/Icons";
import { onScrollAnimationDesktop, returnRefArray } from "../../utilities/animation-helper";
import Video from "../../components/media/video";
import GalleryImagesSlider from "../../components/gallery-slider/gallery-slider";

interface GridProps {
  margin?: string;
}

interface OfficeFeaturesProps {
  content: Array<PageElement>;
}

const OfficeFeautres: React.FC<OfficeFeaturesProps> = ({ content }) => {
  const matchMobile = useMatchMedia("(max-width: 768px)");
  const firstSectionRef = React.useRef<HTMLDivElement>(null);
  const secondSectionRef = React.useRef<HTMLDivElement>(null);
  const thirdSectionRef = React.useRef<HTMLDivElement>(null);
  const fourthSectionRef = React.useRef<HTMLDivElement>(null);
  const fivethSectionRef = React.useRef<HTMLDivElement>(null);
  const tl1 = React.useRef<GSAPTimeline>();
  const tl2 = React.useRef<GSAPTimeline>();
  const tl3 = React.useRef<GSAPTimeline>();
  const tl4 = React.useRef<GSAPTimeline>();
  const tl5 = React.useRef<GSAPTimeline>();

  React.useEffect(() => {
    const firstSectionIndexDesktop: Array<number> = [0, 2, 4, 3, 5, 1];
    const firstSectionIndexMobile: Array<number> = [0, 2, 3, 4, 5];
    const secondSectionIndexDesktop: Array<number> = [0, 1, 2, 5, 9, 7, 8, 4, 6, 3];
    const secondSectionIndexMobile: Array<number> = [0, 1, 2, 3, 4, 5, 6];
    const thirdSectionIndexDesktop: Array<number> = [0, 1, 2, 5, 7, 8, 3, 4, 6];
    const thirdSectionIndexMobile: Array<number> = [0, 1, 2, 3, 4, 5, 6];
    const fourthSectionIndexDesktop: Array<number> = [0, 1, 2, 4, 6, 3, 7, 5];
    const fourthSectionIndexMobile: Array<number> = [0, 1, 2, 3, 4, 5, 6];
    const fivethSectionIndexDesktop: Array<number> = [0, 1, 2, 4, 6, 3, 7, 5];
    const fivethSectionIndexMobile: Array<number> = [0, 1, 2, 3, 4, 5];

    const firstSectionIndex = matchMobile ? firstSectionIndexMobile : firstSectionIndexDesktop;
    const secondSectionIndex = matchMobile ? secondSectionIndexMobile : secondSectionIndexDesktop;
    const thirdSectionIndex = matchMobile ? thirdSectionIndexMobile : thirdSectionIndexDesktop;
    const fourthSectionIndex = matchMobile ? fourthSectionIndexMobile : fourthSectionIndexDesktop;
    const fivethSectionIndex = matchMobile ? fivethSectionIndexMobile : fivethSectionIndexDesktop;

    tl1.current = onScrollAnimationDesktop(firstSectionRef.current, returnRefArray(firstSectionRef.current, firstSectionIndex), matchMobile);
    tl2.current = onScrollAnimationDesktop(secondSectionRef.current, returnRefArray(secondSectionRef.current, secondSectionIndex), matchMobile);
    tl3.current = onScrollAnimationDesktop(thirdSectionRef.current, returnRefArray(thirdSectionRef.current, thirdSectionIndex), matchMobile);
    tl4.current = onScrollAnimationDesktop(fourthSectionRef.current, returnRefArray(fourthSectionRef.current, fourthSectionIndex), matchMobile);
    tl5.current = onScrollAnimationDesktop(fivethSectionRef.current, returnRefArray(fivethSectionRef.current, fivethSectionIndex), matchMobile);

    return () => {
      tl1?.current?.kill();
      tl2?.current?.kill();
      tl3?.current?.kill();
      tl4?.current?.kill();
      tl5?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <ScrollSectionWrapper>
      <Grid ref={firstSectionRef} margin="mb-14 tablet:mb-4">
        <MediaCell1>
          <Headline2 dangerouslySetInnerHTML={{ __html: getElementText(content[0]) }} />
        </MediaCell1>
        <MediaCell2>
          <ImageLeftWrapper>
            <ImageWrapper>
              <PimcoreImage image={getElementImage(content[4])} withAspectRatio />
            </ImageWrapper>
          </ImageLeftWrapper>
        </MediaCell2>
        <MediaCell3>
          <Video placeholder={getElementImage(content[2])} video={getElementVideo(content[2])} />
        </MediaCell3>
        <MediaCell4>
          <QuoteElement
            message="Valley is such a groundbreaking and inspirational place, we are really excited to have our Community Space there."
            name="Dao"
            position="Team Lead HOME"
            modifier="left-4 bottom-4 tablet:left-auto tablet:bottom-24 tablet:right-[22rem]"
          />
          <ImageRightWrapper>
            <ImageWrapper>
              <PimcoreImage image={getElementImage(content[3])} withAspectRatio />
            </ImageWrapper>
          </ImageRightWrapper>
        </MediaCell4>
        <MediaCell5>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[1]) }} />
        </MediaCell5>
        <MediaCell6>
          <FeaturesList
            features={[
              { icon: AwardBadgeIcon, label: "award-winning" },
              { icon: BulbIcon, label: "sustainable" },
            ]}
            margin="mt-0 tablet:mt-8"
          />
        </MediaCell6>
      </Grid>
      <Grid ref={secondSectionRef}>
        <MediaCell7>
          <Headline2 dangerouslySetInnerHTML={{ __html: getElementText(content[5]) }} />
        </MediaCell7>
        <MediaCell8>
          <ImageWrapper>
            <Video placeholder={getElementImage(content[7])} video={getElementVideo(content[7])} />
          </ImageWrapper>
        </MediaCell8>
        <MediaCell9>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[6]) }} />
        </MediaCell9>
        <MediaCell10>
          <QuoteElement
            message="The Community Space has everything you might need in one place, from the latest technology and inspiring collaboration areas to spaces that allow you to network and unwind."
            name="Anna"
            position="HR Coordinator"
            modifier="left-4 bottom-4 tablet:left-auto tablet:bottom-52 tablet:right-[22rem]"
          />
          <ImageWrapper>
            <PimcoreImage image={getElementImage(content[11])} withAspectRatio />
          </ImageWrapper>
        </MediaCell10>
        <MediaCell11>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[12]) }} />
        </MediaCell11>
        <MediaCell12>
          <FeaturesList
            features={[
              {
                icon: OvenIcon,
                label: "fully-equipped kitchen",
              },
              {
                icon: SleepIcon,
                label: (
                  <>
                    sleeping
                    <br />
                    pods
                  </>
                ),
              },
            ]}
            margin="mt-8"
          />
        </MediaCell12>
        <MediaCell13>
          <FeaturesList
            features={[
              {
                icon: PlantIcon,
                label: (
                  <>
                    greenhouse
                    <br />
                    room
                  </>
                ),
              },
              {
                icon: DeskIcon,
                label: (
                  <>
                    ergonomic
                    <br />
                    desks
                  </>
                ),
              },
            ]}
            margin="mt-8"
          />
        </MediaCell13>
        <MediaCell14>
          <ImageWrapper>
            <PimcoreImage image={getElementImage(content[8])} withAspectRatio />
          </ImageWrapper>
        </MediaCell14>
        <MediaCell15>
          <ImageWrapper>
            <PimcoreImage image={getElementImage(content[9])} withAspectRatio />
          </ImageWrapper>
        </MediaCell15>
        <MediaCell16>
          <ImageRightWrapper>
            <ImageWrapper>
              <PimcoreImage image={getElementImage(content[10])} withAspectRatio />
            </ImageWrapper>
          </ImageRightWrapper>
        </MediaCell16>
      </Grid>
      <Grid ref={thirdSectionRef}>
        <MediaCell17>
          <Headline2 dangerouslySetInnerHTML={{ __html: getElementText(content[13]) }} />
        </MediaCell17>
        <MediaCell18>
          <GalleryImagesSlider images={getElementImages(content[15])} />
        </MediaCell18>
        <MediaCell19>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[14]) }} />
        </MediaCell19>
        <MediaCell20>
          <QuoteElement
            message="Miele X really feels like home! Not only do I get to connect with colleagues from all over the world, but also work in a space that reflects our cultures and backgrounds so well."
            name="Sammy"
            position="Hospitality and Events Coordinator"
            modifier="left-4 bottom-4 tablet:left-auto tablet:bottom-44 tablet:right-[21rem]"
          />
          <ImageRightWrapper>
            <ImageWrapper>
              <PimcoreImage image={getElementImage(content[19])} withAspectRatio />
            </ImageWrapper>
          </ImageRightWrapper>
        </MediaCell20>
        <MediaCell21>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[17]) }} />
        </MediaCell21>
        <MediaCell22>
          <FeaturesList
            features={[
              {
                icon: GlobalIcon,
                label: (
                  <>
                    global
                    <br />
                    environment
                  </>
                ),
              },
              {
                icon: BulbPuzzleIcon,
                label: (
                  <>
                    practical,
                    <br />
                    yet fun
                  </>
                ),
              },
            ]}
            margin="mt-8"
          />
        </MediaCell22>
        <MediaCell23>
          <FeaturesList
            features={[
              {
                icon: DownUnderIcon,
                label: (
                  <>
                    ‘down under’
                    <br />
                    room
                  </>
                ),
              },
              {
                icon: TukTukIcon,
                label: "tuk-tuk",
              },
            ]}
            margin="mt-8"
          />
        </MediaCell23>
        <MediaCell24>
          <ImageLeftWrapper>
            <ImageWrapper>
              <PimcoreImage image={getElementImage(content[16])} withAspectRatio />
            </ImageWrapper>
          </ImageLeftWrapper>
        </MediaCell24>
        <MediaCell25>
          <ImageWrapper>
            <PimcoreImage image={getElementImage(content[18])} withAspectRatio />
          </ImageWrapper>
        </MediaCell25>
      </Grid>
      <Grid ref={fourthSectionRef}>
        <MediaCell26>
          <Headline2 dangerouslySetInnerHTML={{ __html: getElementText(content[20]) }} />
        </MediaCell26>
        <MediaCell27>
          <GalleryImagesSlider images={getElementImages(content[22])} />
        </MediaCell27>
        <MediaCell28>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[21]) }} />
        </MediaCell28>
        <MediaCell29>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[25]) }} />
        </MediaCell29>
        <MediaCell30>
          <FeaturesList
            features={[
              {
                icon: BoardroomIco,
                label: <>360&#176; round-table boardroom</>,
              },
              {
                icon: CameraIcon,
                label: (
                  <>
                    high tech
                    <br />
                    meeting rooms
                  </>
                ),
              },
            ]}
            margin="mt-8"
          />
        </MediaCell30>
        <MediaCell31>
          <FeaturesList
            features={[
              {
                icon: TechArenaIcon,
                label: "tech-enabled arena",
              },
              {
                icon: GreenScreenIcon,
                label: "green screen recordings",
              },
            ]}
            margin="mt-8"
          />
        </MediaCell31>
        <MediaCell32>
          <ImageWrapper>
            <PimcoreImage image={getElementImage(content[23])} withAspectRatio />
          </ImageWrapper>
        </MediaCell32>
        <MediaCell33>
          <QuoteElement
            message="Our Community Space is equipped with state-of-the-art technology to facilitate remote collaboration and communication, leading to more productive and innovative outcomes."
            name="Khaled"
            position="IT Rollout Program Lead"
            modifier="left-4 bottom-4 tablet:left-auto tablet:bottom-24 tablet:right-[22rem]"
          />
          <ImageRightWrapper>
            <ImageWrapper>
              <PimcoreImage image={getElementImage(content[24])} withAspectRatio />
            </ImageWrapper>
          </ImageRightWrapper>
        </MediaCell33>
      </Grid>
      <Grid ref={fivethSectionRef}>
        <MediaCell34>
          <Headline2 dangerouslySetInnerHTML={{ __html: getElementText(content[26]) }} />
        </MediaCell34>
        <MediaCell35>
          <ImageWrapper>
            <PimcoreImage image={getElementImage(content[28])} withAspectRatio />
          </ImageWrapper>
        </MediaCell35>
        <MediaCell36>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[27]) }} />
        </MediaCell36>
        <MediaCell37>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[31]) }} />
        </MediaCell37>
        <MediaCell38>
          <FeaturesList
            features={[
              {
                icon: SoundProofIcon,
                label: "sound-proof cabins",
              },
              {
                icon: AeroplaneIcon,
                label: (
                  <>
                    transport
                    <br />
                    links
                  </>
                ),
              },
            ]}
            margin="mt-8"
          />
        </MediaCell38>
        <MediaCell39>
          <FeaturesList
            features={[
              {
                icon: InnovationIcon,
                label: (
                  <>
                    driving
                    <br />
                    innovation
                  </>
                ),
              },
              {
                icon: GlobalEventsIcon,
                label: <>hosting global&nbsp;events</>,
              },
            ]}
            margin="mt-8"
          />
        </MediaCell39>
        <MediaCell40>
          <QuoteElement
            message="Our Community Space allows us to host interactive events and meet colleagues from various global subsidiaries. A great way to build deeper collaboration, exchange learnings and, most importantly, drive the digital future of Miele forward together!"
            name="Candice"
            position="Country Success Specialist"
            modifier="left-4 bottom-4 tablet:-bottom-32 tablet:-left-24"
            left
          />
          <ImageRightWrapper>
            <ImageWrapper>
              <PimcoreImage image={getElementImage(content[29])} withAspectRatio />
            </ImageWrapper>
          </ImageRightWrapper>
        </MediaCell40>
        <MediaCell41>
          <ImageWrapper>
            <PimcoreImage image={getElementImage(content[30])} withAspectRatio />
          </ImageWrapper>
        </MediaCell41>
      </Grid>
    </ScrollSectionWrapper>
  );
};

const Grid = styled.div.attrs<GridProps>(({ margin }) => ({
  className: `grid tablet:grid-cols-12 ${margin ? margin : "mb-14 tablet:mb-36"} px-5 tablet:gap-4`,
})) <GridProps>`
  grid-template-rows: repeat(4, auto);
`;

const ScrollSectionWrapper = styled.div.attrs({ className: "scrollspy" })``;

const MediaCell1 = styled.div.attrs({
  className: `tablet:col-start-8 tablet:col-span-4 tablet:row-start-1 tablet:row-span-1`,
})``;
const MediaCell2 = styled.div.attrs({
  className: `hidden tablet:block tablet:col-start-7 tablet:col-span-4 tablet:row-start-6 tablet:row-span-3`,
})``;
const MediaCell3 = styled.div.attrs({
  className: `tablet:col-start-1 tablet:col-span-6 tablet:row-start-1 tablet:row-span-3 mb-4 tablet:mb-0 tablet:ml-16`,
})``;
const MediaCell4 = styled.div.attrs({
  className: `mb-8 tablet:mb-0 tablet:col-start-3 tablet:col-span-4 tablet:row-start-4 tablet:row-span-4 relative`,
})``;
const MediaCell5 = styled.div.attrs({
  className: `tablet:col-start-8 tablet:col-span-4 tablet:row-start-2 tablet:row-span-3 mb-8 tablet:mb-0`,
})``;
const MediaCell6 = styled.div.attrs({
  className: `tablet:col-start-8 tablet:col-span-4 tablet:row-start-4 tablet:row-span-1 tablet:pr-8`,
})``;
const MediaCell7 = styled.div.attrs({
  className: `tablet:col-start-2 tablet:col-span-4 tablet:row-start-1 tablet:row-span-2 tablet:pr-8`,
})``;
const MediaCell8 = styled.div.attrs({
  className: `mb-8 tablet:mb-0 tablet:col-start-7 tablet:col-span-6 tablet:row-start-1 tablet:row-span-3`,
})``;
const MediaCell9 = styled.div.attrs({
  className: `mb-8 tablet:mb-0 tablet:col-start-2 tablet:col-span-4 tablet:row-start-3 tablet:row-span-3 tablet:pr-4`,
})``;
const MediaCell10 = styled.div.attrs({
  className: `relative mb-8 tablet:mb-0 tablet:col-start-3 tablet:col-span-4 tablet:row-start-12 tablet:row-span-4 tablet:z-10`,
})``;
const MediaCell11 = styled.div.attrs({
  className: `tablet:col-start-8 tablet:col-span-4 tablet:row-start-9 tablet:row-span-4 tablet:pr-4`,
})``;
const MediaCell12 = styled.div.attrs({
  className: `tablet:col-start-2 tablet:col-span-4 tablet:row-start-5 tablet:row-span-3 tablet:pr-8`,
})``;
const MediaCell13 = styled.div.attrs({
  className: `tablet:col-start-8 tablet:col-span-4 tablet:row-start-13 tablet:row-span-1 tablet:pr-8`,
})``;
const MediaCell14 = styled.div.attrs({
  className: `hidden tablet:block tablet:col-start-7 tablet:col-span-3 tablet:row-start-4 tablet:row-span-4 tablet:pr-14`,
})``;
const MediaCell15 = styled.div.attrs({
  className: `hidden tablet:block tablet:col-start-9 tablet:col-span-3 tablet:row-start-4 tablet:row-span-4 tablet:pl-14`,
})``;
const MediaCell16 = styled.div.attrs({
  className: `hidden tablet:block tablet:col-start-2 tablet:col-span-5 tablet:row-start-7 tablet:row-span-5 `,
})``;
const MediaCell17 = styled.div.attrs({
  className: `tablet:col-start-2 tablet:col-span-3 tablet:row-start-1 tablet:row-span-1 `,
})``;
const MediaCell18 = styled.div.attrs({
  className: `tablet:col-start-7 tablet:col-span-6 tablet:row-start-1 tablet:row-span-3 mb-8 tablet:mb-0 -mx-5 tablet:mx-0 tablet:pt-12`,
})``;
const MediaCell19 = styled.div.attrs({
  className: `tablet:col-start-2 tablet:col-span-4 tablet:row-start-2 tablet:row-span-1 mb-8 tablet:mb-0`,
})``;
const MediaCell20 = styled.div.attrs({
  className: `relative tablet:col-start-3 tablet:col-span-4 tablet:row-start-11 tablet:row-span-4 mb-8 tablet:mb-0 z-10`,
})``;
const MediaCell21 = styled.div.attrs({
  className: `tablet:col-start-8 tablet:col-span-4 tablet:row-start-9 tablet:row-span-3 tablet:pr-4`,
})``;
const MediaCell22 = styled.div.attrs({
  className: `tablet:col-start-2 tablet:col-span-4 tablet:row-start-3 tablet:row-span-2`,
})``;
const MediaCell23 = styled.div.attrs({
  className: `tablet:col-start-8 tablet:col-span-4 tablet:row-start-12 tablet:row-span-1`,
})``;
const MediaCell24 = styled.div.attrs({
  className: `hidden tablet:block tablet:col-start-7 tablet:col-span-5 tablet:row-start-4 tablet:row-span-4`,
})``;
const MediaCell25 = styled.div.attrs({
  className: `hidden tablet:block tablet:col-start-3 tablet:col-span-4 tablet:row-start-6 tablet:row-span-5`,
})``;
const MediaCell26 = styled.div.attrs({
  className: `tablet:col-start-2 tablet:col-span-3 tablet:row-start-1 tablet:row-span-1`,
})``;
const MediaCell27 = styled.div.attrs({
  className: `tablet:col-start-7 tablet:col-span-6 tablet:row-start-2 tablet:row-span-5 mb-8 tablet:mb-0 -mx-5 tablet:mx-0`,
})``;
const MediaCell28 = styled.div.attrs({
  className: `tablet:col-start-2 tablet:col-span-4 tablet:row-start-2 tablet:row-span-1 mb-8 tablet:mb-0`,
})``;
const MediaCell29 = styled.div.attrs({
  className: `tablet:col-start-8 tablet:col-span-4 tablet:row-start-7 tablet:row-span-3`,
})``;
const MediaCell30 = styled.div.attrs({
  className: `tablet:col-start-2 tablet:col-span-4 tablet:row-start-3 tablet:row-span-1`,
})``;
const MediaCell31 = styled.div.attrs({
  className: `tablet:col-start-8 tablet:col-span-4 tablet:row-start-10 tablet:row-span-2`,
})``;
const MediaCell32 = styled.div.attrs({
  className: `hidden tablet:block tablet:col-start-2 tablet:col-span-5 tablet:row-start-4 tablet:row-span-5 tablet:pt-32`,
})``;
const MediaCell33 = styled.div.attrs({
  className: `hidden tablet:block tablet:col-start-3 tablet:col-span-4 tablet:row-start-9 tablet:row-span-4`,
})``;
const MediaCell34 = styled.div.attrs({
  className: `tablet:col-start-8 tablet:col-span-3 tablet:row-start-1 tablet:row-span-1`,
})``;
const MediaCell35 = styled.div.attrs({
  className: `tablet:col-start-1 tablet:col-span-6 tablet:row-start-1 tablet:row-span-3 mb-8 tablet:mb-0`,
})``;
const MediaCell36 = styled.div.attrs({
  className: `tablet:col-start-8 tablet:col-span-4 tablet:row-start-2 tablet:row-span-2 mb-8 tablet:mb-0`,
})``;
const MediaCell37 = styled.div.attrs({
  className: `tablet:col-start-2 tablet:col-span-4 tablet:row-start-9 tablet:row-span-2 mb-8 tablet:mb-0 tablet:pr-2`,
})``;
const MediaCell38 = styled.div.attrs({
  className: `tablet:col-start-8 tablet:col-span-4 tablet:row-start-4 tablet:row-span-1`,
})``;
const MediaCell39 = styled.div.attrs({
  className: `tablet:col-start-2 tablet:col-span-4 tablet:row-start-11 tablet:row-span-1`,
})``;
const MediaCell40 = styled.div.attrs({
  className: `hidden tablet:block tablet:col-start-3 tablet:col-span-4 tablet:row-start-4 tablet:row-span-3`,
})``;
const MediaCell41 = styled.div.attrs({
  className: `hidden tablet:block tablet:col-start-7 tablet:col-span-6 tablet:row-start-6 tablet:row-span-6`,
})``;

const ImageWrapper = styled.div.attrs({ className: "rounded-lg overflow-hidden" })``;

export default OfficeFeautres;